import * as React from "react"
import type { GatsbyBrowser } from "gatsby"
import { useState } from "react";
import { ColorScheme, ColorSchemeProvider, MantineProvider } from "@mantine/core";
import 'prismjs/themes/prism-okaidia.css';
import  Layout  from './src/components/layout'
import { DoubleHeader } from "./src/components/DoubleHeader/DoubleHeader";


export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
}) => {

    const [colorScheme, setColorScheme] = useState<ColorScheme>('light');
    const toggleColorScheme = (value?: ColorScheme) =>
      setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));  

  return (
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      <MantineProvider theme={{ colorScheme }} withGlobalStyles withNormalizeCSS>
      <DoubleHeader mainLinks={[]} userLinks={[]} />
      <Layout pageTitle="" breadcrumbItems={null}>
        {element}
      </Layout>
      </MantineProvider>
    </ColorSchemeProvider>
  )
}

/**
 * 
 * https://mantine.dev/guides/dark-theme/
 * https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 * 
 */