import React from 'react'
import { Breadcrumbs } from '@mantine/core';
import { Link } from 'gatsby';

export interface IPageBreadcrumbs {
    items: any;
}

function PageBreadcrumbs(props: IPageBreadcrumbs) {
  
    const blankLink = '#';

    return (
    <>
      <Breadcrumbs>
      
      {props.items.map((item: any) => (
        (item.href ?
            <Link
                to={item.href ? item.href : blankLink }
                key={item.title}
            >
            {item.title}
            </Link> : item.title
        )
        
      
      ))}
      {/* {items} */}
      </Breadcrumbs>
    </>
  );
}

export default PageBreadcrumbs;

/**
 * 
 * 
 * https://mantine.dev/core/breadcrumbs/
 * 
 * 
 */