import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Container } from '@mantine/core'
import { DoubleHeader } from './DoubleHeader/DoubleHeader'
import PageBreadcrumbs from './PageBreadcrumbs/PageBreadcrumbs'
import { FooterLinks } from './PageFooter/PageFooter'

export interface ILayoutProps {
    pageTitle: string,
    children: any,
    breadcrumbItems: any
}

const Layout = ( props: ILayoutProps) => {

  const data = useStaticQuery(graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }
  `)
    
  return (
    <>
    <Container>
      { props.breadcrumbItems ?  <PageBreadcrumbs items={props.breadcrumbItems} /> : '' }
      <main>
        <h1>{props.pageTitle}</h1>
        {props.children}
      </main>
    </Container>
    <FooterLinks data={[]} />
    </>
  )
}

export default Layout