exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-add-a-project-reference-to-a-csharp-project-using-command-line-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/github/workspace/ui/blog/add-a-project-reference-to-a-csharp-project-using-command-line/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-add-a-project-reference-to-a-csharp-project-using-command-line-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-adding-entra-id-identity-provider-to-b-2-c-custom-policy-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/github/workspace/ui/blog/adding-entra-id-identity-provider-to-b2c-custom-policy/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-adding-entra-id-identity-provider-to-b-2-c-custom-policy-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-adding-favicon-to-your-gatsby-project-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/github/workspace/ui/blog/adding-favicon-to-your-gatsby-project/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-adding-favicon-to-your-gatsby-project-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-adding-inline-images-with-gatsby-in-markdown-mdx-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/github/workspace/ui/blog/adding-inline-images-with-gatsby-in-markdown-mdx/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-adding-inline-images-with-gatsby-in-markdown-mdx-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-adding-logging-to-a-blazor-client-side-web-assembly-app-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/github/workspace/ui/blog/adding-logging-to-a-blazor-client-side-web-assembly-app/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-adding-logging-to-a-blazor-client-side-web-assembly-app-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-azure-cdn-front-door-bicep-template-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/github/workspace/ui/blog/azure-cdn-front-door-bicep-template/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-azure-cdn-front-door-bicep-template-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-azure-devops-quick-reference-guide-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/github/workspace/ui/blog/azure-devops-quick-reference-guide/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-azure-devops-quick-reference-guide-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-azure-function-access-to-xmlhttprequest-from-origin-has-been-blocked-by-cors-policy-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/github/workspace/ui/blog/azure-function-access-to-xmlhttprequest-from-origin-has-been-blocked-by-cors-policy/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-azure-function-access-to-xmlhttprequest-from-origin-has-been-blocked-by-cors-policy-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-cant-see-remote-branch-in-vscode-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/github/workspace/ui/blog/cant-see-remote-branch-in-vscode/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-cant-see-remote-branch-in-vscode-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-clear-terminal-screen-macos-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/github/workspace/ui/blog/clear-terminal-screen-macos/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-clear-terminal-screen-macos-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-cloning-devops-repo-authentication-failed-error-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/github/workspace/ui/blog/cloning-devops-repo-authentication-failed-error/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-cloning-devops-repo-authentication-failed-error-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-configuring-azure-static-webapp-with-custom-domain-cpanel-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/github/workspace/ui/blog/configuring-azure-static-webapp-with-custom-domain-cpanel/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-configuring-azure-static-webapp-with-custom-domain-cpanel-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-converting-numbers-from-cardinal-to-ordinal-csharp-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/github/workspace/ui/blog/converting-numbers-from-cardinal-to-ordinal-csharp/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-converting-numbers-from-cardinal-to-ordinal-csharp-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-create-a-blank-solution-and-add-csharp-projects-to-it-using-command-line-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/github/workspace/ui/blog/create-a-blank-solution-and-add-csharp-projects-to-it-using-command-line/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-create-a-blank-solution-and-add-csharp-projects-to-it-using-command-line-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-creating-a-teams-multi-tenant-tab-application-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/github/workspace/ui/blog/creating-a-teams-multi-tenant-tab-application/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-creating-a-teams-multi-tenant-tab-application-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-creating-azure-b-2-c-custom-policies-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/github/workspace/ui/blog/creating-azure-b2c-custom-policies/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-creating-azure-b-2-c-custom-policies-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-csharp-function-to-calculate-tax-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/github/workspace/ui/blog/csharp-function-to-calculate-tax/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-csharp-function-to-calculate-tax-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-enforce-nodejs-version-in-azure-static-webapp-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/github/workspace/ui/blog/enforce-nodejs-version-in-azure-static-webapp/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-enforce-nodejs-version-in-azure-static-webapp-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-how-to-identify-and-free-up-a-used-port-mac-os-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/github/workspace/ui/blog/how-to-identify-and-free-up-a-used-port-mac-os/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-how-to-identify-and-free-up-a-used-port-mac-os-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-installing-brew-on-macos-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/github/workspace/ui/blog/installing-brew-on-macos/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-installing-brew-on-macos-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-installing-dotnet-project-template-using-dotnet-cli-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/github/workspace/ui/blog/installing-dotnet-project-template-using-dotnet-cli/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-installing-dotnet-project-template-using-dotnet-cli-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-installing-nvm-on-macos-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/github/workspace/ui/blog/installing-nvm-on-macos/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-installing-nvm-on-macos-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-pdf-viewer-to-open-pdf-files-in-your-webapp-reactjs-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/github/workspace/ui/blog/pdf-viewer-to-open-pdf-files-in-your-webapp-reactjs/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-pdf-viewer-to-open-pdf-files-in-your-webapp-reactjs-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-pushed-files-to-github-but-nothing-appears-in-repo-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/github/workspace/ui/blog/pushed-files-to-github-but-nothing-appears-in-repo/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-pushed-files-to-github-but-nothing-appears-in-repo-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-record-entire-screen-macos-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/github/workspace/ui/blog/record-entire-screen-macos/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-record-entire-screen-macos-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-running-azure-function-from-vscode-terminal-mac-os-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/github/workspace/ui/blog/running-azure-function-from-vscode-terminal-mac-os/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-running-azure-function-from-vscode-terminal-mac-os-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-smtp-authentication-is-disabled-for-tenant-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/github/workspace/ui/blog/smtp-authentication-is-disabled-for-tenant/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-smtp-authentication-is-disabled-for-tenant-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-starting-function-app-from-command-line-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/github/workspace/ui/blog/starting-function-app-from-command-line/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-starting-function-app-from-command-line-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-transferring-tenant-ownership-from-m-365-developer-tenant-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/github/workspace/ui/blog/transferring-tenant-ownership-from-m365-developer-tenant/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-transferring-tenant-ownership-from-m-365-developer-tenant-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-using-null-coalescing-operator-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/github/workspace/ui/blog/using-null-coalescing-operator/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-using-null-coalescing-operator-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-using-prismjs-in-gatsby-using-mdx-files-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/github/workspace/ui/blog/using-prismjs-in-gatsby-using-mdx-files/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-using-prismjs-in-gatsby-using-mdx-files-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-using-switch-expressions-csharp-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/github/workspace/ui/blog/using-switch-expressions-csharp/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-using-switch-expressions-csharp-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-using-window-management-tool-macos-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/github/workspace/ui/blog/using-window-management-tool-macos/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-using-window-management-tool-macos-index-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

