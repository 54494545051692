import React, { useState, useEffect } from 'react';
import { createStyles, Header, Container, Group, Burger, rem, useMantineTheme, Transition, Paper } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link, graphql, useStaticQuery, navigate } from 'gatsby';
import ThemeSelector from '../ThemeSelector/ThemeSelector';
import HeaderLinks from '../HeaderLinks/HeaderLinks';

const HEADER_HEIGHT = rem(84);

const useStyles = createStyles((theme) => ({
  inner: {
    height: HEADER_HEIGHT,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  burger: {
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  links: {
    paddingTop: theme.spacing.lg,
    height: HEADER_HEIGHT,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  mobileMenu: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing.md,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    position: 'absolute',
    top: HEADER_HEIGHT,
    left: 0,
    right: 0,
    zIndex: 1000,
  },

  mainLinks: {
    marginRight: `calc(${theme.spacing.sm} * -1)`,
  },

  mainLink: {
    textTransform: 'uppercase',
    fontSize: rem(13),
    color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[6],
    padding: `${rem(7)} ${theme.spacing.sm}`,
    fontWeight: 700,
    borderBottom: `${rem(2)} solid transparent`,
    transition: 'border-color 100ms ease, color 100ms ease',

    '&:hover': {
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      textDecoration: 'none',
    },
  },

  secondaryLink: {
    color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[6],
    fontSize: theme.fontSizes.xs,
    textTransform: 'uppercase',
    transition: 'color 100ms ease',

    '&:hover': {
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      textDecoration: 'none',
    },
  },

  mainLinkActive: {
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    borderBottomColor: theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 5 : 6],
  },

  showOnMobile: {
    display: 'none',
    [theme.fn.smallerThan('sm')]: {
      display: 'block',
    },
  },
  hideOnMobile: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },
}));

interface LinkProps {
  label: string;
  link: string;
  target?: string;
  type: string;
}

interface DoubleHeaderProps {
  mainLinks: LinkProps[];
  userLinks: LinkProps[];
}

export function DoubleHeader({ mainLinks, userLinks }: DoubleHeaderProps) {
  const [opened, { toggle, close }] = useDisclosure(false);
  const [active, setActive] = useState('/');

  const links: LinkProps[] = [
    {
      link: '/',
      label: 'Home',
      type: 'internal'
    },
    {
      link: '/blog',
      label: 'Blog',
      type: 'internal'
    },
    {
      link: 'https://ko-fi.com/apps365',
      label: 'Donate a coffee',
      target: '_blank',
      type: 'external'
    }
  ];

  const query = useStaticQuery(graphql`
    query {
      lightLogo: allFile(filter: { name: { eq: "apps365_200x200_light_w_outline" } }) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      darkLogo: allFile(filter: { name: { eq: "apps365_200x200_dark" } }) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);

  const { classes, cx } = useStyles();

  const lightLogo = getImage(query.lightLogo.edges[0].node);
  const darkLogo = getImage(query.darkLogo.edges[0].node);

  const theme = useMantineTheme();
  const dark = theme.colorScheme === 'dark';
  const logoImage = dark ? darkLogo : lightLogo;

  useEffect(() => {
    const path = window.location.pathname;
    setActive(path);
  }, []);

  return (
    <Header height={HEADER_HEIGHT} mb={50}>
      <Container className={classes.inner}>
        {logoImage && (
          <Link to='/'>
            <GatsbyImage 
              style={{ width: '70px' }}
              image={logoImage} 
              alt="test"
            />
          </Link>
        )}
  
        <div className={classes.links}>
          <Group position="right">
            {links.map((link) => (
              link.type === 'external' ? (
                <a
                  key={link.label}
                  href={link.link}
                  target={link.target}
                  className={cx(classes.mainLink, {
                    [classes.mainLinkActive]: active === link.link,
                  })}
                  onClick={() => setActive(link.link)}
                >
                  {link.label}
                </a>
              ) : (
                <Link
                  key={link.label}
                  to={link.link}
                  className={cx(classes.mainLink, {
                    [classes.mainLinkActive]: active === link.link,
                  })}
                  onClick={() => setActive(link.link)}
                >
                  {link.label}
                </Link>
              )
            ))}
            <ThemeSelector />
          </Group>
        </div>
        <Burger opened={opened} onClick={toggle} className={classes.burger} size="sm" />
      </Container>
  
      <Transition transition="pop-top-right" duration={200} mounted={opened}>
        {(styles) => (
          <div className={classes.showOnMobile}>
            <Paper className={`${classes.mobileMenu}`} style={styles}>
              {links.map((link) => (
                link.type === 'external' ? (
                  <a
                    key={link.label}
                    href={link.link}
                    target={link.target}
                    className={cx(classes.mainLink, {
                      [classes.mainLinkActive]: active === link.link,
                    })}
                    onClick={() => {
                      setActive(link.link);
                      close();
                    }}
                  >
                    {link.label}
                  </a>
                ) : (
                  <Link
                    key={link.label}
                    to={link.link}
                    className={cx(classes.mainLink, {
                      [classes.mainLinkActive]: active === link.link,
                    })}
                    onClick={() => {
                      setActive(link.link);
                      close();
                    }}
                  >
                    {link.label}
                  </Link>
                )
              ))}
              <ThemeSelector />
            </Paper>
          </div>
        )}
      </Transition>
    </Header>
  );  
}

/**
 * 
 * https://ui.mantine.dev/category/headers
 * 
 */
